<template>
    <div class="page flex-col">
        <header>
            <van-nav-bar
                    title="我的推广"
                    left-text=""
                    left-arrow
                    @click-left="onClickLeft"
            />
        </header>

        <div class="block_1 flex-col">
            <div class="group_2 flex-col">
                <!--<span class="text_6">收益记录</span>-->

                <div class="section_1 flex-row" v-for="(user, index) in constants" :key="index">
                    <div class="block_2 flex-col">
                        <img src="./assets/img/huiyuan.png" alt="">
                    </div>
                    <div class="text-wrapper_2 flex-col justify-between">
                        <span class="text_7">{{user.name}}（{{user.typeName}}）</span>
                        <span class="text_8">{{user.account}}</span>
                    </div>
                    <div class="text-wrapper_3">
                        <span class="text_9">{{user.createTime}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {queryUserByAccount,queryMemberTg} from "@/api/user";

    export default {
        data() {
            return {
                memberAccount: '',
                loopData0: [],
                constants: {}
            };
        },
        mounted() {
            this.loopData0 = []
            const user = localStorage.getItem('userInfoDs')
            if (user) {
                this.memberAccount = JSON.parse(user).account
                queryUserByAccount(this.memberAccount).then(response => {
                    if (response.code == 200) {
                        this.foodIntegral = response.data.foodIntegral  //美食积分
                        this.healthIntegral = response.data.healthIntegral // 养生积分
                        this.queryMemberTg(this.memberAccount)
                    }
                });
            } else {
                this.$router.push('/login')
            }
        },
        methods: {
            queryMemberTg(memberAccount) {
                queryMemberTg(memberAccount).then(response => {
                    if (response.code == 200) {
                        this.constants = response.data
                    }
                })

            },
            onClickLeft() {
                this.$router.push('/wode')
            },
            cancelTx() {
                this.$model({
                    show: true,
                    title: "提示",
                    content: "请前往门店，打开微信扫一扫，扫描门店二维码申请提现",
                    confirmButton: false,
                    cancelButton: true
                });
                return false
            }
        }
    };
</script>
<style scoped lang="css" src="./assets/tgindex.rem.css"/>